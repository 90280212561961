/*
    KÜS FZSD Theme für Bootstrap 4

    https://getbootstrap.com/docs/4.1/getting-started/theming/

*/ 

$kues-blue: #2c334e;
$kues-purple: #d60b52;

// Color system
$theme-colors: (
  "primary": $kues-purple,
  "secondary": $kues-blue,
);

// Links
$link-color: $kues-blue;

// Fonts

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("./assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

$font-family-sans-serif: "Roboto";
$font-family-base: "Roboto";

// Typography
$headings-font-family: "Roboto";
$headings-font-weight: 700;

// Buttons
$btn-font-family: "Roboto";
$btn-font-weight: 700;

// Components
$component-active-bg: $kues-blue;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$border-width: 0; // Allgemein: Keine Ränder

// Cards
$card-bg: rgba($kues-blue, 0.08);
$card-cap-bg: $kues-blue;
$card-cap-color: white;
.card-header {
  font-weight: bold;
}

// Tables
$table-bg: rgba($kues-blue, 0.08);
$table-color: black;

$table-border-width: 1px;
$table-border-color: $kues-blue;
$table-dark-border-color: $kues-blue;

$table-dark-color: white;
$table-dark-bg: $kues-blue;

// Bootstraps Default Values
@import "../node_modules/bootstrap/scss/bootstrap";

// File-Input "Öffnen..." Button
.custom-file-label::after {
  content: "Öffnen...";
  background-color: $kues-blue;
  color: white;
  border: solid 1px white;
}
